import { app } from '@/plugins/appAxios'
// import { api } from '@/plugins/axios'
import { mangeApi } from '@/plugins/mangeAxios'

// 获取微信配置
export function getWechatConfig (params) {
  return app('/get_js_sdk_config', params)
}

// 获取微信配置
export function getArticleShareUrl (params) {
  return mangeApi('/extension_article/share_url', params)
}
